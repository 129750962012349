import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueScrollTo from 'vue-scrollto'

import 'normalize.css'

import BreakpointsMixin from '@/mixins/breakpoints'

import DefaultLayoutSection from '@/views/layouts/section'

Vue.component('DefaultLayoutSection', DefaultLayoutSection)

Vue.config.productionTip = false

Vue.mixin(BreakpointsMixin)

Vue.use(VueScrollTo, {
  offset () {
    const header = document.querySelector('.app-header')

    // делаем отступ при скролле противоположный высоте header
    if (header) {
      return -1 * header.offsetHeight
    }

    return 0
  }
})

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
