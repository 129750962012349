/**
 * Показатель отображения полной платформы
 * @type {string}
 */
export const PLATFORM_MODE_PLATFORM = 'platform'
/**
 * Показатель отображения только плеера
 * @type {string}
 */
export const PLATFORM_MODE_PLAYER = 'player'
/**
 * Показатель отображения только расписания
 * @type {string}
 */
export const PLATFORM_MODE_SCHEDULE = 'schedule'
/**
 * Показатель отображения только чата
 * @type {string}
 */
export const PLATFORM_MODE_CHAT = 'chat'
