<template>
  <nav class="app-navigation">
    <a
      v-for="(link, key) in menuLinks"
      :key="key"
      v-scroll-to="`${link.href}`"
      :href="link.href"
      class="app-navigation__link"
      @click="$emit('navigation:click')"
    >
      {{ link.name }}
    </a>
  </nav>
</template>

<script>
import configurations from '@/configurations'
import { mapGetters } from 'vuex'

export default {
  name: 'AppNavigation',
  computed: {
    ...mapGetters(['isHasActivities']),
    isHasPartners () {
      return configurations.content.partners && configurations.content.partners.length
    },
    isHasSpeakers () {
      return configurations.content.speakers && configurations.content.speakers.length
    },
    menuLinks () {
      return [
        ...this.withPlayerAndSchedule,
        ...this.withSpeakers,
        ...this.withPartners
      ]
    },
    withPlayerAndSchedule () {
      return this.isHasActivities ? [
        {
          name: 'Платформа WeCloud.Events',
          href: '#player'
        },
        {
          name: 'Расписание',
          href: '#schedule'
        }
      ] : []
    },
    withSpeakers () {
      return this.isHasSpeakers ? [
        {
          name: 'Спикеры',
          href: '#speakers'
        }
      ] : []
    },
    withPartners () {
      return this.isHasPartners ? [
        {
          name: 'Партнеры',
          href: '#partners'
        }
      ] : []
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.app-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.25;
  color: var(--menu-text-color);

  @media #{$md} {
    align-items: end;
  }

  @media #{$xl} {
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    color: var(--primary-text-color);
  }

  &__link {
    margin-bottom: 40px;
    color: inherit;
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$xl} {
      margin-right: 64px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
