<template>
  <section class="speakers">
    <default-layout-section class="speakers__content">
      <h2 class="speakers__title g-h2">
        Спикеры
      </h2>
      <div
        ref="speakersWrapper"
        class="speakers__wrapper"
      >
        <div
          ref="speakersList"
          class="speakers__list"
        >
          <card-comp
            v-for="(speaker, key) in speakers"
            ref="speaker"
            :key="key"
            class="speakers__item"
            :speaker="speaker"
          />
        </div>
      </div>
      <div
        v-if="isShowDropDownList"
        class="speakers__actions"
      >
        <app-button
          class="speakers__more-button"
          @click="showMoreClickHandler"
        >
          <span :class="['speakers__more-button-icon', { 'speakers__more-button-icon_active': isShowSpeakers }]" />
          {{ isShowSpeakers ? 'Свернуть' : 'Все спикеры' }}
        </app-button>
      </div>
      <span class="blur speakers__blur speakers__blur_first" />
      <span class="blur speakers__blur speakers__blur_second" />
    </default-layout-section>
  </section>
</template>

<script>
import CardComp from '@/views/blocks/speakers/components/card'
import AppButton from '@/views/components/app-button'

export default {
  name: 'Speakers',
  components: { CardComp, AppButton },
  props: {
    speakers: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      isShowSpeakers: false
    }
  },
  computed: {
    isShowDropDownList () {
      return this.speakers.length > 4
    }
  },
  beforeMount () {
    if (this.isShowDropDownList) {
      window.addEventListener('resize', this.changeHeightSpeakersWrapper)
    }
  },
  mounted () {
    if (this.isShowDropDownList) {
      this.hideSpeakers()
    }
  },
  beforeDestroy () {
    if (this.isShowDropDownList) {
      window.removeEventListener('resize', this.changeHeightSpeakersWrapper)
    }
  },
  methods: {
    /**
     * Хендлер для кнопки показать/свернуть
     */
    showMoreClickHandler () {
      if (this.isShowSpeakers) {
        this.hideSpeakers()
      } else {
        this.showSpeakers()
      }

      this.isShowSpeakers = !this.isShowSpeakers
    },
    /**
     * Раскрываем список спикеров
     */
    showSpeakers () {
      const speakersWrapperHeight = this.$refs.speakersList.offsetHeight

      this.$refs.speakersWrapper.style.height = `${speakersWrapperHeight}px`
    },
    /**
     * Возвращаем высоту свернутого списка
     * @returns {number|*}
     */
    getSmallHeightSpeakersWrapper () {
      const rowGap = parseInt(getComputedStyle(this.$refs.speakersList).rowGap) // отступ между строками в grid

      switch (true) {
        case this.$isXL:
        case this.$isLG:
          // на разрешениях xl и lg свернутый список отображает 4 карточки в строку
          // так как все карточки имеют одинаковую высоту в grid
          // возвращаем высоту 1 карточки
          return this.$refs.speaker[0].$el.offsetHeight
        case this.$isMD:
          // на разрешении md свернутый список отображает 4 карточки в 2 строки
          // так как все карточки имеют одинаковую высоту в grid
          // возвращаем сумму высот 1 и 3 карточки и отступ между строками
          return this.$refs.speaker[0].$el.offsetHeight + this.$refs.speaker[2].$el.offsetHeight + rowGap
        default:
          // на всех остальных разрешениях свернутый список отображает 4 карточки в столбец
          // возвращаем сумму высот с 1 до 4 карточки и сумму 3 отступов
          return this.$refs.speaker.slice(0, 4).reduce((sum, item) => sum + item.$el.offsetHeight, 0) + (rowGap * 3)
      }
    },
    /**
     * Сворачиваем список спикеров
     */
    hideSpeakers () {
      const speakersWrapperHeight = this.getSmallHeightSpeakersWrapper()

      this.$refs.speakersWrapper.style.height = `${speakersWrapperHeight}px`
    },
    /**
     * Обновляем высоту блока спикеров
     */
    changeHeightSpeakersWrapper () {
      if (this.isShowSpeakers) {
        this.showSpeakers()
      } else {
        this.hideSpeakers()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.speakers {
  border-bottom: 1px solid var(--divider-backgound);
  overflow: hidden;

  &__content {
    position: relative;
    padding: 40px 16px;

    @media #{$md} {
      padding: 40px 24px;
    }

    @media #{$lg} {
      padding: 40px 80px;
    }

    @media #{$xl} {
      padding: 40px 100px;
    }
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &__wrapper {
    transition: height 0.5s ease;
    overflow: hidden;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px;

    @media #{$md} {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
    }

    @media #{$lg} {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__more-button {
    display: flex;
    font-size: 20px;
    line-height: 1.6;
    color: var(--primary-color);
    letter-spacing: 1px;
    align-items: center;
  }

  &__more-button-icon {
    position: relative;
    margin-right: 14px;
    width: 20px;
    height: 20px;
    border: 2px solid currentColor;
    border-radius: 50%;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 2px;
      background-color: currentColor;
    }

    &:before {
      transform: translate(-50%, -50%);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
      transition: transform 0.2s ease;
    }

    &_active:after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  &__blur {
    &_first {
      top: 810px;
      right: -217px;
      background-color: var(--blur-color-5);
      filter: blur(100px);

      @media #{$sm} {
        top: 766px;
        right: -272px;
      }

      @media #{$md} {
        top: 582px;
        right: -129px;
      }

      @media #{$lg} {
        top: 432px;
        right: 0;
      }

      @media #{$xl} {
        top: 477px;
        right: -55px;
      }
    }

    &_second {
      display: none;
      background-color: var(--blur-color-4);
      filter: blur(100px);

      @media #{$md} {
        top: 925px;
        left: 0;
        display: block;
      }

      @media #{$lg} {
        top: 467px;
        left: 13px;
      }

      @media #{$xl} {
        top: 557px;
        left: 160px;
      }
    }
  }
}
</style>
