import SpeakersConfiguration from '@/configurations/content/speakers'
import PartnersConfiguration from '@/configurations/content/partners' // @/configurations/content/partners-dark для темной темы

export default {
  logo: {
    image: require('@/assets/logo/logo.svg'), // @/assets/logo/logo-white.svg для темной темы
    alt: 'Mashroom logo'
  },
  aboutActivity: {
    name: 'CLOUD НА ЛЬДУ',
    description: [
      'Приглашаем Вас на новогодний вечер ❤️',
      'Почувствуй себя настоящим фигуристом или опытным участником жюри!',
      'На платформе WeCloud.Events вы сможете посмотреть прямой эфир и голосовать за своих!',
      'Начало трансляции в 19:30 ⏰',
      'В Парке «Красная Пресня» на катке «The city» сможете покататься на коньках и выпить горячий глинтвейн.',
      'Сбор гостей в 18:30!',
      'Одевайтесь потеплее🧣',
      'Мы ждем Вас и будем рады встрече, как офлайн, так и онлайн!!❤️🤗'
    ],
    date: '22.12.2022'
// time: ''
  },
  activities: [
    {
      hash: 'jRpMPPPp',
      id: 182,
      tabName: '' // название таба, при многозальном мероприятии
    }
  ],
  speakers: SpeakersConfiguration,
  partners: PartnersConfiguration,
  footer: {
    copyright: 'WeCloud.Events (C) ',
    phone: 'Как добраться:  парк Красная Пресня, ул. Мантулинская, 5, Москва!'
  }
}
