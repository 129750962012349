<template>
  <div :class="['app-mobile-menu', menuOpenClass]">
    <app-button
      class="app-mobile-menu__close-btn"
      title="Закрыть меню"
      button-type="close"
      @click="closeMenu"
    />
    <app-navigation @navigation:click="closeMenu" />
  </div>
</template>

<script>
import AppNavigation from '@/views/components/app-navigation'
import AppButton from '@/views/components/app-button'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'AppMobileMenu',
  components: { AppButton, AppNavigation },
  computed: {
    ...mapState(['isMenuOpen']),
    menuOpenClass () {
      return this.isMenuOpen ? 'app-mobile-menu_open' : ''
    }
  },
  beforeMount () {
    window.addEventListener('resize', this.closeMenu)
    document.addEventListener('click', this.documentClickHandler)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.closeMenu)
    document.removeEventListener('click', this.documentClickHandler)
  },
  methods: {
    ...mapMutations(['setIsMenuOpen']),
    openMenu () {
      this.setIsMenuOpen(true)
    },
    closeMenu () {
      this.setIsMenuOpen(false)
    },
    documentClickHandler (evt) {
      if (this.isMenuOpen && !evt.target.closest('header') && !evt.target.closest('.app-mobile-menu')) {
        this.closeMenu()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.app-mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 108px 16px 40px;
  width: 100vw;
  height: 100vh;
  background-color: var(--menu-background);
  transform: translateX(110%);
  transition: transform 0.3s ease-in-out;

  &_open {
    transform: translateX(0);
  }

  @media #{$md} {
    padding: 156px 24px 40px;
    width: 328px;
    box-shadow:
      0 9px 46px 8px rgba(0, 0, 0, 0.12),
      0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }

  &__close-btn {
    position: absolute;
    top: 24px;
    right: 16px;
    color: var(--menu-text-color);
  }
}
</style>
