export default [
  {
    fullName: 'Иван Григорьев',
    description: 'CEO AI банка "Вперед, и точка!", Россия',
    photo: require('@/assets/speakers/speaker-1.jpg')
  },
  {
    fullName: 'Оксана Вельмитаминова',
    description: 'Заместитель председателя, член правления банка "Вперед, и точка!", Россия',
    photo: require('@/assets/speakers/speaker-2.jpg')
  },
  {
    fullName: 'Фрэнк Вернье',
    description: 'Консультант по вопросам этики AI, Франция',
    photo: require('@/assets/speakers/speaker-3.jpg')
  },
  {
    fullName: 'Фридрих Вильгельм Генрих Александр фон Гумбольдт',
    description: 'Инвестор, CEO фонда "AIF", Люксембург',
    photo: require('@/assets/speakers/speaker-4.jpg')
  },
  {
    fullName: 'Фрэнк Вернье',
    description: 'Консультант по вопросам этики AI, Франция',
    photo: require('@/assets/speakers/speaker-5.jpg')
  },
  {
    fullName: 'Оксана Вельмитаминова',
    description: 'Заместитель председателя, член правления банка "Вперед, и точка!", Россия',
    photo: require('@/assets/speakers/speaker-6.jpg')
  },
  {
    fullName: 'Иван Григорьев',
    description: 'CEO AI банка "Вперед, и точка!", Россия',
    photo: require('@/assets/speakers/speaker-7.jpg')
  },
  {
    fullName: 'Фридрих Вильгельм Генрих Александр фон Гумбольдт',
    description: 'Инвестор, CEO фонда "AIF", Люксембург',
    photo: require('@/assets/speakers/speaker-8.jpg')
  }
]
