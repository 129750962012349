<template>
  <section class="schedule">
    <default-layout-section class="schedule__wrapper">
      <div class="schedule__content">
        <h2 class="schedule__title g-h2">
          Расписание
        </h2>
      </div>
      <app-tabs :platform-mode="platformMode" />
      <span class="blur blur_large schedule__blur schedule__blur_first" />
      <span class="blur schedule__blur schedule__blur_second" />
      <span class="blur schedule__blur schedule__blur_third" />
    </default-layout-section>
  </section>
</template>

<script>
import AppTabs from '@/views/components/app-tabs'
import { PLATFORM_MODE_SCHEDULE } from '@/constants/platform'

export default {
  name: 'Schedule',
  components: { AppTabs },
  data () {
    return {
      platformMode: PLATFORM_MODE_SCHEDULE
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.schedule {
  border-bottom: 1px solid var(--divider-backgound);

  @media #{$xl} {
    padding-bottom: 40px;
  }

  &__wrapper {
    position: relative;
  }

  &__content {
    padding: 40px 16px 0;

    @media #{$md} {
      padding: 40px 24px 0;
    }

    @media #{$lg} {
      padding: 40px 80px 0;
    }

    @media #{$xl} {
      padding: 40px 100px 0;
    }
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &__blur {
    &_first {
      top: -178px;
      right: -132px;
      background-color: var(--blur-color-4);
      filter: blur(100px);

      @media #{$sm} {
        top: -190px;
        right: -152px;
      }

      @media #{$md} {
        top: -87px;
        right: -111px;
      }

      @media #{$lg} {
        top: -182px;
        right: 145px;
      }

      @media #{$xl} {
        top: -43px;
        right: auto;
        left: 348px;
      }
    }

    &_second {
      right: -171px;
      bottom: -301px;
      background-color: var(--blur-color-4);
      filter: blur(100px);

      @media #{$sm} {
        right: -201px;
        bottom: -354px;
      }

      @media #{$md} {
        right: -256px;
        bottom: -261px;
      }

      @media #{$lg} {
        right: 0;
        bottom: -65px;
      }

      @media #{$xl} {
        top: -82px;
        right: 24px;
        bottom: auto;
      }
    }

    &_third {
      top: -65px;
      left: -197px;
      background-color: var(--blur-color-5);
      filter: blur(100px);

      @media #{$sm} {
        top: -58px;
        left: -258px;
      }

      @media #{$md} {
        top: -44px;
        left: -269px;
      }

      @media #{$lg} {
        top: -139px;
      }

      @media #{$xl} {
        top: 303px;
      }
    }
  }
}
</style>
