<template>
  <header class="app-header">
    <default-layout-section class="app-header__wrapper">
      <span class="app-header__logo">
        <img
          :src="logo.image"
          :alt="logo.alt"
        >
      </span>
      <template v-if="isShowMenu">
        <span class="app-header__divider" />
        <app-navigation class="app-header__navigation" />
        <app-button
          class="app-header__menu-open-btn"
          title="Открыть меню"
          button-type="menu"
          @click="openMenu"
        />
      </template>
    </default-layout-section>
  </header>
</template>

<script>
import AppButton from '@/views/components/app-button'
import AppNavigation from '@/views/components/app-navigation'
import { mapMutations } from 'vuex'
import configurations from '@/configurations'

export default {
  name: 'AppHeader',
  components: { AppNavigation, AppButton },
  props: {
    isShowMenu: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      logo: configurations.content.logo
    }
  },
  methods: {
    ...mapMutations(['setIsMenuOpen']),
    openMenu () {
      this.setIsMenuOpen(true)
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: var(--header-background);
  border-bottom: 1px solid var(--divider-backgound);

  &__wrapper {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;

    @media #{$md} {
      padding: 8px 24px;
    }

    @media #{$lg} {
      padding: 8px 80px;
    }

    @media #{$xl} {
      padding: 8px 100px;
    }
  }

  &__logo {
    line-height: 0;

    img {
      height: 56px;
    }
  }

  &__divider {
    display: block;
    margin: -8px 16px -8px auto;
    width: 1px;
    height: 72px;
    background-color: var(--divider-backgound);

    @media #{$md} {
      margin: -8px 24px -8px auto;
    }

    @media #{$lg} {
      margin: -8px 80px -8px auto;
    }

    @media #{$xl} {
      margin: -8px auto -8px 80px;
    }
  }

  &__navigation {
    display: none;

    @media #{$xl} {
      display: flex;
    }
  }

  &__menu-open-btn {
    @media #{$xl} {
      display: none;
    }
  }
}
</style>
