<template>
  <section :class="['player', { 'player_only': isOnlyPlatformLandingType }]">
    <default-layout-section class="player__wrapper">
      <div
        v-if="isShowTitle"
        class="player__content"
      >
        <h2 class="player__title g-h2">
          Платформа WeCloud.events
        </h2>
      </div>
      <app-tabs :platform-mode="platformMode" />
      <span
        v-if="isShowBackground"
        class="blur blur_x-large player__blur"
      />
    </default-layout-section>
  </section>
</template>

<script>
import AppTabs from '@/views/components/app-tabs'
import { PLATFORM_MODE_PLATFORM, PLATFORM_MODE_PLAYER, PLATFORM_MODE_CHAT } from '@/constants/platform'
import { PLAYER_LANDING_TYPE, ONLY_PLATFORM_TYPE, OFFLINE_VIEWER_TYPE } from '@/constants'
import configurations from '@/configurations'

export default {
  name: 'Player',
  components: { AppTabs },
  data () {
    return {
      isShowBackground: configurations.view.main.isShowBackground
    }
  },
  computed: {
    platformMode () {
      if (process.env.VUE_APP_VIEWER_TYPE === OFFLINE_VIEWER_TYPE) return PLATFORM_MODE_CHAT

      return process.env.VUE_APP_LANDING_TYPE === PLAYER_LANDING_TYPE ? PLATFORM_MODE_PLAYER : PLATFORM_MODE_PLATFORM
    },
    isShowTitle () {
      return configurations.view.main.player.isShowTitle
    },
    isOnlyPlatformLandingType () {
      return process.env.VUE_APP_LANDING_TYPE === ONLY_PLATFORM_TYPE
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.player {
  border-bottom: 1px solid var(--divider-backgound);

  &_only {
    border: none;
  }

  @media #{$xl} {
    padding-bottom: 40px;

    &_only {
      padding-bottom: 0;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__content {
    padding: 40px 16px 0;

    @media #{$md} {
      padding: 40px 24px 0;
    }

    @media #{$lg} {
      padding: 40px 80px 0;
    }

    @media #{$xl} {
      padding: 40px 100px 0;
    }
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &__blur {
    top: -198px;
    right: -168px;
    background-color: var(--blur-color-4);
    filter: blur(100px);

    @media #{$sm} {
      top: -170px;
      right: -113px;
    }

    @media #{$md} {
      top: -32px;
      right: -19px;
    }

    @media #{$lg} {
      top: -27px;
      right: 237px;
    }

    @media #{$xl} {
      top: 167px;
      right: auto;
      left: 11px;
    }
  }
}
</style>
