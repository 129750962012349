export default [
  {
    name: 'Schuco',
    logo: require('@/assets/partners/schuco.svg'),
    href: '#'
  },
  {
    name: 'Аэрофлот',
    logo: require('@/assets/partners/aeroflot.svg'),
    href: '#'
  },
  {
    name: 'Юнипро',
    logo: require('@/assets/partners/unipro.svg'),
    href: '#'
  },
  {
    name: 'ВТБ',
    logo: require('@/assets/partners/vtb.svg'),
    href: '#'
  },
  {
    name: 'Сбер',
    logo: require('@/assets/partners/sber.svg'),
    href: '#'
  },
  {
    name: 'Amway',
    logo: require('@/assets/partners/amway.svg'),
    href: '#'
  }
]
