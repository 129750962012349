<template>
  <div :class="['about-activity', { 'about-activity_with-border': isShowBorder }]">
    <default-layout-section class="about-activity__wrapper">
      <template v-if="!isOnlyTitle">
        <div
          class="about-activity__content about-activity__content_date"
        >
          <time class="about-activity__date">{{ date }}</time>
          <p class="about-activity__date">{{ status }}</p>
          <time class="about-activity__time">{{ time }}</time>
        </div>
        <hr class="about-activity__divider">
      </template>
      <div class="about-activity__content">
        <h1 class="about-activity__name g-h1">
          {{ name }}
        </h1>
        <div
          v-if="!isOnlyTitle"
          class="about-activity__description"
        >
          <p
            v-for="(item, key) in description"
            :key="key"
            class="about-activity__paragraph"
          >
            {{ item }}
          </p>
        </div>
      </div>
      <template v-if="isShowBackground">
        <span class="blur blur_medium about-activity__blur about-activity__blur_first" />
        <span class="blur blur_small about-activity__blur about-activity__blur_second" />
        <span class="blur blur_medium about-activity__blur about-activity__blur_third" />
      </template>
    </default-layout-section>
  </div>
</template>

<script>
import configurations from '@/configurations'

export default {
  name: 'AboutActivity',
  props: {
    isOnlyTitle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowBackground: configurations.view.main.isShowBackground,
      isShowBorder: configurations.view.main.aboutActivity.isShowBorder,
      name: configurations.content.aboutActivity.name,
      description: configurations.content.aboutActivity.description,
      date: configurations.content.aboutActivity.date,
      status: configurations.content.aboutActivity.status,
      time: configurations.content.aboutActivity.time
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.about-activity {
  &_with-border {
    border-bottom: 1px solid var(--divider-backgound);
  }

  &__wrapper {
    position: relative;
  }

  &__content {
    padding: 24px 16px 40px;

    &_date {
      display: flex;
      justify-content: space-between;
      padding-top: 40px;
    }

    @media #{$md} {
      padding: 40px 24px;

      &_date {
        justify-content: start;
      }
    }

    @media #{$lg} {
      padding: 40px 80px;
    }

    @media #{$xl} {
      padding: 40px 100px;
    }
  }

  &__date,
  &__time {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 5px;
  }

  &__date {
    @media #{$md} {
      margin-right: 80px;
    }
  }

  &__divider {
    position: relative;
    left: 50%;
    margin: 0;
    width: 100vw;
    height: 1px;
    background: var(--divider-backgound);
    border: none;
    transform: translateX(-50%);
  }

  &__name {
    margin: 0;
  }

  &__description {
    margin-top: 40px;
  }

  &__paragraph {
    margin: 0;
  }

  &__paragraph + &__paragraph {
    margin-top: 20px;
  }

  &__blur {
    &_first {
      top: 59px;
      left: -53px;
      background-color: var(--blur-color-1);
      filter: blur(80px);

      @media #{$md} {
        top: 52px;
        left: 69px;
      }
    }

    &_second {
      top: 251px;
      right: -55px;
      background-color: var(--blur-color-2);
      filter: blur(100px);

      @media #{$sm} {
        right: -66px;
      }

      @media #{$md} {
        top: 238px;
        right: auto;
        left: 311px;
      }

      @media #{$lg} {
        top: 215px;
      }

      @media #{$xl} {
        top: 252px;
      }
    }

    &_third {
      top: -57px;
      right: -136px;
      background-color: var(--blur-color-3);
      filter: blur(80px);

      @media #{$md} {
        right: -19px;
      }

      @media #{$lg} {
        top: -79px;
        right: 0;
      }

      @media #{$xl} {
        top: 0;
        right: 24px;
      }
    }
  }
}
</style>
