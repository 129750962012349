import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMenuOpen: false,
    activities: null,
    stand: process.env.VUE_APP_STAND,
    code: ''
  },
  getters: {
    isHasActivities: state => state.activities && Boolean(state.activities.length)
  },
  mutations: {
    setIsMenuOpen (state, payload) {
      state.isMenuOpen = payload
    },
    setActivities (state, payload) {
      state.activities = payload
    },
    setStand (state, payload) {
      state.stand = payload
    },
    setCode (state, payload) {
      state.code = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
