<template>
  <div class="default-layout-section">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DefaultLayoutSection'
}
</script>

<style lang="scss">
.default-layout-section {
  margin: 0 auto;
  max-width: 1440px;
}
</style>
