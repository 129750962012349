import { MAX_LANDING_TYPE, MIN_LANDING_TYPE, PLAYER_LANDING_TYPE, ONLY_PLATFORM_TYPE } from '@/constants'
import maxViewConfiguration from '@/configurations/view/max'
import minViewConfiguration from '@/configurations/view/min'
import playerViewConfiguration from '@/configurations/view/player'
import onlyPlatformViewConfiguration from '@/configurations/view/only-platform'

let viewConfiguration = null

switch (process.env.VUE_APP_LANDING_TYPE) {
  case MAX_LANDING_TYPE:
    viewConfiguration = maxViewConfiguration
    break
  case MIN_LANDING_TYPE:
    viewConfiguration = minViewConfiguration
    break
  case PLAYER_LANDING_TYPE:
    viewConfiguration = playerViewConfiguration
    break
  case ONLY_PLATFORM_TYPE:
    viewConfiguration = onlyPlatformViewConfiguration
    break
  default:
    viewConfiguration = playerViewConfiguration
}

export default viewConfiguration
