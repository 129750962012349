<template>
  <footer class="app-footer">
    <span class="app-footer__copyright">{{ copyright }} {{ year }}</span>
    <a
      v-if="phone"
      class="app-footer__phone"
      :href="`tel:${phone}`"
    >{{ phone }}</a>
  </footer>
</template>

<script>
import configurations from '@/configurations'

export default {
  name: 'AppFooter',
  data () {
    return {
      copyright: configurations.content.footer.copyright,
      year: new Date().getFullYear(),
      phone: configurations.content.footer.phone
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.app-footer {
  padding: 24px 16px;
  font-size: 12px;
  line-height: 1.3;
  color: var(--light-text-color);
  text-align: center;
  background-color: var(--footer-background);

  &__phone {
    display: block;
    margin-top: 8px;
    color: inherit;
    text-decoration: none;
  }
}
</style>
