/**
 * Тема по умолчанию
 * @type {string}
 */
export const DEFAULT_LANDING_THEME = 'default'
/**
 * Черно-белая тема
 * @type {string}
 */
export const BLACK_AND_WHITE_LANDING_THEME = 'black-and-white'
/**
 * Темная тема
 * @type {string}
 */
export const DARK_LANDING_THEME = 'dark'
/**
 * Зеленая тема
 * @type {string}
 */
export const GREEN_LANDING_THEME = 'green'
/**
 * Красная тема
 * @type {string}
 */
export const RED_LANDING_THEME = 'red'
/**
 * Катомная тема (для всякого разного)
 * @type {string}
 */
export const CUSTOM_LANDING_THEME = 'custom'
/**
 * Массив тем лендинга
 * @type {string[]}
 */
export const LANDING_THEMES = [
  DEFAULT_LANDING_THEME,
  BLACK_AND_WHITE_LANDING_THEME,
  DARK_LANDING_THEME,
  GREEN_LANDING_THEME,
  RED_LANDING_THEME,
  CUSTOM_LANDING_THEME
]
