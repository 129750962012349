<template>
  <div class="app">
    <app-header
      v-if="isShowHeader"
      class="app__header"
      :is-show-menu="isShowMenu"
    />
    <main :class="['app__content', { 'app__content_only': isOnlyPlatformLandingType }]">
      <about-activity
        v-if="isShowAboutActivity"
        :is-only-title="isPlayerLandingType"
      />
      <player
        v-if="isHasActivities"
        id="player"
      />
      <schedule
        v-if="isShowSchedule"
        id="schedule"
      />
      <speakers
        v-if="isShowSpeakers"
        id="speakers"
        :speakers="speakers"
      />
      <partners
        v-if="isShowPartners"
        id="partners"
        :partners="partners"
      />
    </main>
    <app-footer
      v-if="isShowFooter"
      class="app__footer"
    />
    <div :class="['app__overlay', overlayActiveClass]" />
    <app-mobile-menu v-if="isShowMenu" />
  </div>
</template>

<script>
import AppHeader from '@/views/components/app-header'
import AppFooter from '@/views/components/app-footer'
import AboutActivity from '@/views/blocks/about-activity'
import Partners from '@/views/blocks/partners'
import Player from '@/views/blocks/player'
import Schedule from '@/views/blocks/schedule'
import Speakers from '@/views/blocks/speakers'
import AppMobileMenu from '@/views/components/app-mobile-menu'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { STANDS, PLATFORM_STAND_URLS } from '@/constants/stands'
import configurations from '@/configurations'
import { ONLY_PLATFORM_TYPE, PLAYER_LANDING_TYPE } from '@/constants'
import { DEFAULT_LANDING_THEME, LANDING_THEMES } from '@/constants/themes'

export default {
  name: 'App',
  components: {
    AppFooter,
    AppHeader,
    AboutActivity,
    Partners,
    Player,
    Schedule,
    Speakers,
    AppMobileMenu
  },
  data () {
    return {
      activityName: configurations.content.aboutActivity.name,
      partners: configurations.content.partners,
      speakers: configurations.content.speakers
    }
  },
  computed: {
    ...mapGetters(['isHasActivities']),
    ...mapState(['isMenuOpen', 'activities', 'stand']),
    overlayActiveClass () {
      return this.isMenuOpen ? 'app__overlay_active' : ''
    },
    isHasAboutActivity () {
      return Boolean(configurations.content.aboutActivity)
    },
    isHasPartners () {
      return this.partners && this.partners.length
    },
    isHasSpeakers () {
      return this.speakers && this.speakers.length
    },
    isShowHeader () {
      return configurations.view.header.isShow
    },
    isShowMenu () {
      return configurations.view.header.isShowMenu
    },
    isShowAboutActivity () {
      return configurations.view.main.aboutActivity.isShow && this.isHasAboutActivity
    },
    isShowSchedule () {
      return configurations.view.main.schedule.isShow && this.isHasActivities
    },
    isShowSpeakers () {
      return configurations.view.main.speakers.isShow && this.isHasSpeakers
    },
    isShowPartners () {
      return configurations.view.main.partners.isShow && this.isHasPartners
    },
    isShowFooter () {
      return configurations.view.footer.isShow
    },
    isPlayerLandingType () {
      return process.env.VUE_APP_LANDING_TYPE === PLAYER_LANDING_TYPE
    },
    isOnlyPlatformLandingType () {
      return process.env.VUE_APP_LANDING_TYPE === ONLY_PLATFORM_TYPE
    }
  },
  watch: {
    isMenuOpen (bool) {
      if (bool) {
        return document.body.classList.add('g-overflow-hidden')
      }

      document.body.classList.remove('g-overflow-hidden')
    }
  },
  beforeMount () {
    const url = new URL(location.href)
    const stand = url.searchParams.get('stand')
    const code = url.searchParams.get('code')

    // добавляем класс на body для переключения темы
    if (LANDING_THEMES.includes(process.env.VUE_APP_LANDING_THEME) && process.env.VUE_APP_LANDING_THEME !== DEFAULT_LANDING_THEME) {
      document.body.classList.add(process.env.VUE_APP_LANDING_THEME)
    }

    switch (true) {
      case Boolean(url.searchParams.get('activities')):
        this.setActivities(url.searchParams.get('activities').split(',').map(activityHash => {
          return {
            hash: activityHash,
            id: null
          }
        }))
        break
      case Boolean(configurations.content.activities && configurations.content.activities.length):
        this.setActivities(configurations.content.activities)
        break
      default:
        this.setActivities([{ hash: 'TestPage', id: null }])
    }

    if (stand && STANDS.includes(stand)) {
      this.setStand(stand)
    }

    if (code) {
      this.setCode(code)
    }

    this.connectPlatform()
  },
  methods: {
    ...mapMutations(['setActivities', 'setStand', 'setCode']),
    /**
     * Создаем link для подключения стилей платформы
     * @returns {HTMLLinkElement}
     */
    createPlatformStyleLink () {
      const link = document.createElement('link')

      link.rel = 'stylesheet'
      link.href = `${PLATFORM_STAND_URLS[this.stand]}/iframe/css/index.css`

      return link
    },
    /**
     * Создаем script для подключения скрипта платформы
     * @returns {HTMLScriptElement}
     */
    createPlatformScript () {
      const script = document.createElement('script')

      script.src = `${PLATFORM_STAND_URLS[this.stand]}/iframe/js/index.js`

      return script
    },
    /**
     * Подключаем платформу
     */
    connectPlatform () {
      const fragment = document.createDocumentFragment()

      fragment.append(this.createPlatformStyleLink())
      fragment.append(this.createPlatformScript())

      document.head.append(fragment)
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/main.scss";

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header,
  &__footer {
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
    padding-top: 73px;

    &_only {
      padding-top: 0;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: var(--overlay-background);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s 0.1s ease-in-out;

    &_active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
