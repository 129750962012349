export default {
  header: {
    isShow: true,
    isShowMenu: false
  },
  main: {
    isShowBackground: false,
    aboutActivity: {
      isShow: true,
      isShowBorder: false
    },
    player: {
      isShow: true,
      isShowTitle: false
    },
    schedule: {
      isShow: false
    },
    speakers: {
      isShow: false
    },
    partners: {
      isShow: false
    }
  },
  footer: {
    isShow: true
  }
}
