<template>
  <div class="app-tabs">
    <div
      v-if="isShowTabs"
      class="app-tabs__top"
    >
      <app-button
        v-for="(activity, key) in activities"
        :key="`tab-${platformMode}-${key}`"
        :class="['app-tabs__tab btn', {'app-tabs__tab_active': activeTab === key}]"
        @click="switchTab(key)"
      >
        <template v-if="activity.tabName">
          {{ activity.tabName }}
        </template>
        <template v-else>
          Зал {{ key + 1 }}
        </template>
      </app-button>
    </div>

    <div
      v-for="(activity, key) in activities"
      :key="`${platformMode}-${key}`"
      :class="['app-tabs__content', { 'app-tabs__content_active': activeTab === key }]"
    >
      <platform-comp
        v-if="activeTab === key"
        :hash="activity.hash"
        :activity-id="activity.id"
        :mode="platformMode"
      />
    </div>
  </div>
</template>

<script>
import AppButton from '@/views/components/app-button'
import PlatformComp from '@/views/components/app-tabs/components/platform'
import { mapState } from 'vuex'
import { PLATFORM_MODE_PLATFORM } from '@/constants/platform'

export default {
  name: 'AppTabs',
  components: { AppButton, PlatformComp },
  props: {
    platformMode: {
      type: String,
      default: PLATFORM_MODE_PLATFORM
    }
  },
  data () {
    return {
      activeTab: 0
    }
  },
  computed: {
    ...mapState(['activities']),
    isShowTabs () {
      return this.activities.length >= 2
    }
  },
  methods: {
    switchTab (id) {
      this.activeTab = id
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.app-tabs {
  &__top {
    display: flex;
    margin: 0 auto;
    max-width: 1240px;
  }

  &__tab {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px;
    font-size: 20px;
    line-height: 1.6;
    color: var(--primary-color);
    background-color: var(--light-color);
    border: 1px solid var(--primary-color);
    transition: background-color 0.2s ease, color 0.2s ease;

    &_active {
      color: var(--light-text-color);
      background-color: var(--primary-color);
    }
  }

  //&__content {
  //  &_active {
  //    min-height: 634px;
  //
  //    @media #{$sm} {
  //      min-height: 664px;
  //    }
  //
  //    @media #{$md} {
  //      min-height: 876px;
  //    }
  //
  //    @media #{$lg} {
  //      min-height: 976px;
  //    }
  //
  //    @media #{$xl} {
  //      min-height: 546px;
  //    }
  //  }
  //}
}
</style>
