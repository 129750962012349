/**
 * Максимальный тип лендинга
 * @type {string}
 */
export const MAX_LANDING_TYPE = 'max'
/**
 * Минимальный тип лендинга
 * @type {string}
 */
export const MIN_LANDING_TYPE = 'min'
/**
 * Тип лендинга только плеер
 * @type {string}
 */
export const PLAYER_LANDING_TYPE = 'player'
/**
 * Тип лендинга только с платформой (без шапки и подвала)
 * @type {string}
 */
export const ONLY_PLATFORM_TYPE = 'only-platform'
/**
 * Тип присутствия зрителя offline
 * @type {string}
 */
export const OFFLINE_VIEWER_TYPE = 'offline'
